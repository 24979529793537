<template>
    <div class="detail-class">
        <van-sticky>
            <div class="detail-class__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="请输入会员或教练名称"
                    @search="onSearch"
                    >
                </van-search>
            </div>
            <van-tabs v-model="active" class="my-tabs" @change="changeCourse">
                <van-tab title="未上课"></van-tab>
                <van-tab title="已上课"></van-tab>
            </van-tabs>
        </van-sticky>
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                <div class="my-course-list">
                    <my-course v-for="item in list" :key="item.id" :item="item" :complete="item.curriculumTimeStatus !== 2">
                        <div slot="type">
                            <div class="info coach" @click="goMember(item.memberId, item.id)">
                                <div class="label">学员</div>
                                <div class="value">{{item.memberName}}<van-icon name="arrow" /></div>
                            </div>
                            <div class="info level">
                                <div class="label">教练</div>
                                <div class="value">{{item.coachName}}</div>
                            </div>
                        </div>
                    </my-course>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
            <no-data v-show="showData"/>
        </van-pull-refresh>
    </div>
</template>

<script>

/* decisive:
/person/base/member/member-show

decisive:
/person/bill/memberRecharge/memberRechargel-list
 */
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'person/bill/curriculumTime/curriculumTime-list',
            value: '',
            active: 0,
            model: {}
        }
    },
    methods: {
        init() {
            this.initList()
        },
        onSearch() {
            this.$set(this.model, 'keyword', this.value)
            this.init()
        },
        goMember(memberId, id) {
            this.$router.push({path: '/person/memberclass', query: {
                memberId,
                id
            }})
        },
        changeCourse(data) {
            //console.log(data)
            this.model = {}
            this.$set(this.model, 'memberId', this.$route.query.id)
            if(data === 0) {
                this.model.curriculumTimeStatus = 2
            }else{
                this.model.curriculumTimeStatusStart = 3
                this.model.curriculumTimeStatusEnd = 6
            }
            this.init()
        }
    },
    mounted() {
        this.$set(this.model, 'curriculumTimeStatus', 2)
        this.$set(this.model, 'memberId', this.$route.query.id)
        this.init()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .detail-class{
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        .my-tabs{
            .van-tabs__line{
                background-color: $main;
                width: 80px;
            }
            
        }
        .my-pull-refresh{
            .my-list{
                padding: 0 25px;
            }
            .my-course-list{
                .My-Course {
                    padding: 10px 0;
                    .course {
                        margin-right: unset;
                    }
                }
            }
        }
    }

</style>